import { DialogService } from "aurelia-dialog";
import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { autoinject } from "aurelia-framework";
import { Router } from "aurelia-router";
import { Prompt } from "elements/prompt";
import { ValidationMessage } from "models";
import { Models } from "models/core";
import { ErrorService } from "services/error-service";
import { NetService } from "services/net-service";
import { ServiceService } from "services/service-service";
import { ToastService } from "services/toast-service";
import { TranslationService } from "services/translation-service";

@autoinject
export class NetDetailCertification {
  private net: Models.Net;
  private nextTabIndex: number = null;

  protected certificateApproved: boolean = false;
  protected locked: boolean = false;

  private subscription: Subscription;
  private validationMessages: Map<string, string[]> = new Map();
  protected hasValidationMessages: boolean = false;

  constructor(
    private errorService: ErrorService,
    private netService: NetService,
    private toastService: ToastService,
    private serviceService: ServiceService,
    private router: Router,
    private eventAggregator: EventAggregator,
    private dialogService: DialogService,
    private trservice: TranslationService,
  ) {}

  protected activate(params: { Id: number }) {
    this.getNet(params.Id);

    this.validate(params.Id);

    // refresh if locked / unlocked
    this.subscription = this.eventAggregator.subscribe("netLocked", () => {
      this.getNet(params.Id);
    });
    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.eventAggregator.subscribe("changeTab", (tabIndex: number) => {
      this.nextTabIndex = tabIndex;
    });
  }

  private async validate(netId: number) {
    try {
      const result = await this.validateNetBeforeApprove(netId);
      this.hasValidationMessages = result?.length > 0;

      for (const message of result) {
        const m = this.trservice.getLocalizedValue(message, "Message");
        const c = this.trservice.getLocalizedValue(message, "Category");
        const existingMessage = this.validationMessages.get(c);
        if (!existingMessage) {
          this.validationMessages.set(c, [m]);
        } else {
          existingMessage.push(m);
        }
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected deactivate() {
    if (this.subscription) {
      this.subscription.dispose();
    }
  }

  private getNet(id) {
    this.netService
      .get(id)
      .then((res) => {
        this.net = res;
        this.locked = res.Locked ? res.Locked : false;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  protected signAndLock() {
    return this.dialogService
      .open({
        viewModel: Prompt,
        model: {
          header: "dialog.signAndLockHeader",
          message: "dialog.signAndLockText",
          actions: {
            delete: { enabled: false },
            save: { enabled: false },
            cancel: { enabled: true, t: "dialog.cancel" },
            dontsave: { enabled: false },
            continue: { enabled: true, t: "dialog.signAndLock" },
          },
        },
      })
      .whenClosed((response) => {
        if (response.wasCancelled) {
          return false;
        } else {
          const result = response.output;
          if (result === "continue") {
            this.netService
              .approveNet(this.net.Id)
              .then((res) => {
                this.toastService.showSuccess("net.locked");
                window.location.reload();
              })
              .catch((err) => this.errorService.handleError(err));
            return false;
          } else {
            return true;
          }
        }
      });
  }

  protected canDeactivate() {
    this.eventAggregator.publish("changeTab-success", this.nextTabIndex);
    return true;
  }

  protected async validateNetBeforeApprove(netId: number) {
    return this.netService.validateNetBeforeApproval(
      netId,
    ) as unknown as ValidationMessage[];
  }

  protected setCertificateFileId(event: any) {
    this.net.CertificateFileId = event.detail.id;
    this.netService
      .put(this.net, this.net.Id)
      .then((res) => {
        this.getNet(this.net.Id);
        this.toastService.showSuccess("net.certificateUploaded");
      })
      .catch((err) => this.errorService.handleError(err));
  }

  removeCertificateFileId() {
    this.net.CertificateFileId = null;
    this.netService
      .put(this.net, this.net.Id)
      .then((res) => {
        this.getNet(this.net.Id);
        this.toastService.showSuccess("net.certificateDeleted");
      })
      .catch((err) => this.errorService.handleError(err));
  }
}
