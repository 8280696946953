import { SpecialProductTypeService } from './../../services/special-product-type-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject, bindable, BindingEngine, LogManager } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { activationStrategy, Router } from 'aurelia-router';
import $ from 'jquery';
import { Models } from 'models/core';
import { UserModels } from 'models/UserModels';
import { CustomerService } from 'services/customer-service';
import { ErrorService } from 'services/error-service';
import { NetService } from 'services/net-service';
import { NetStatusService } from 'services/net-status-service';
import { ProducerService } from 'services/producer-service';
import { UserService } from 'services/user-service';
import { AuthorizeStep } from './../../authorizeStep';
import { roles } from './../../routes';
import * as moment from 'moment';
import { ListSettingsService } from 'services/list-settings-service';
import { NetTypeService } from 'services/net-type-service';
import { Logger } from 'aurelia-logging';
import { connectTo } from 'aurelia-store';
import { ApplicationState } from 'lib/state';
import { FileAttachmentModels } from 'models/FileAttachmentModels';
import { FileService } from 'services/file-service';
import { Field } from 'components/service/service-list/fields';
import { MaterialService } from 'services/material-service';
const SELECTED_NET_COLUMNS = 'SELECTED_NET_COLUMNS';

const logger: Logger = LogManager.getLogger('net-list');

@autoinject
@connectTo()
export class NetList {
  private tableData: Array<any>;
  private netToUpdate: Models.Net;
  private customers: Array<Models.Customer> = [];
  private ready: boolean;
  private searchInputHasFocus = false;
  private statuses: Array<Models.NetStatus> = [];
  private userData: UserModels.User;
  private netOwnerFilter = 'all';
  private totalItems = 15;
  private params: any;
  private netTypes: Array<Models.NetType>;
  private specialProductTypes: Array<Models.SpecialProductType>;

  private exportInProgress = false;

  @bindable
  private currentPage = 1;
  @bindable
  private customerid;
  @bindable
  private context: string;

  private canCreateUsedNet = false;
  private selectedFields: Array<any>;
  private NET_FILTERS_KEY = 'NET_FILTERS_KEY';
  private showSearchDefault = false;

  private fields = null;

  private tableViewType = 1;
  private tableViewVerticalLines = false;
  private tableMargin = 2;
  private pageSize = 25;
  private searchTextSubscription: Subscription;

  private isSpecialProductList = false;

  private showNetFilters = true;
  private netFiltersList: Array<any> = [];
  @bindable
  private filtersVisible: any = null;
  @bindable
  private hideNetFilters = false;

  private netTypeCheckboxes = [];
  private netTypeCheckboxesObserver: any;

  private specialProductTypeCheckboxes = [];
  private specialProductTypeCheckboxesObserver: any;

  private state: ApplicationState;

  // FILTERS
  private netFilters: any;
  private netFilterGetFiltersQuery: any;
  private netFilterToggleVisible: any;
  private netFilterClearAll: any;

  constructor(
    private netService: NetService,
    private customerService: CustomerService,
    private errorService: ErrorService,
    private netStatusService: NetStatusService,
    private userService: UserService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private producerService: ProducerService,
    private bindingEngine: BindingEngine,
    private i18n: I18N,
    private listSettingsService: ListSettingsService,
    private netTypeService: NetTypeService,
    private specialProductTypeService: SpecialProductTypeService,
    private fileService: FileService,
    private materialService: MaterialService
  ) {}

  private getFilterKey() {
    return this.NET_FILTERS_KEY + (this.context ? '_' + this.context : '');
  }

  private getFilterListKey() {
    return 'NET_FILTER_LIST' + (this.context ? '_' + this.context : '');
  }

  private activate(params) {
    this.params = params;
  }

  private attached(params) {
    this.ready = false;

    this.isSpecialProductList =
      this.router.currentInstruction.config.name === 'special-product-list' ||
      (this.context && this.context.indexOf('special-product-list') >= 0);

    if (this.isSpecialProductList && !this.context) {
      this.context = 'special-product-list';
    }

    if (this.isSpecialProductList == undefined) {
      this.isSpecialProductList = false;
    }

    if (!this.params && params) {
      this.params = params;
    } else if (!params && this.params) {
      params = this.params;
    }
    if (!this.params) {
      this.params = {};
      params = {};
    }

    Promise.all([this.userService.getCurrentUser(), this.listSettingsService.getDefaultPageSize()])
      .then((responses) => {
        this.userData = responses[0];

        if (
          AuthorizeStep.auth.roles &&
          AuthorizeStep.auth.roles.find((x) => x === roles.administrator || x === roles.serviceuser)
        ) {
          this.canCreateUsedNet = true;
        }

        this.fields = this.getFields();

        // get selected values from localstorage
        const selectedFields: Array<string> = JSON.parse(
          localStorage.getItem(SELECTED_NET_COLUMNS + (this.context ? '_' + this.context : ''))
        );

        if (selectedFields) {
          // deselect all fields, then reselect the selected fields
          this.fields.forEach((field) => {
            // hidden fields are not deselected, because this can cause problems
            // when adding new hidden fields needed for system purposes
            if (field.visible) {
              field.selected = false;
            }
          });

          selectedFields.forEach((selectedField) => {
            const field = this.fields.find((x) => x.field === selectedField);
            if (field) {
              field.selected = true;
            }
          });
        }

        // list-settings
        this.pageSize = responses[1];

        this.getNets();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private determineActivationStrategy() {
    return activationStrategy.replace;
  }

  private buildQueryString(obj) {
    const str = [];
    for (const p in obj) {
      if (!p.startsWith('_') && obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }
    return str.join('&');
  }

  private pushState() {
    const baseUrl = '#' + this.router.baseUrl + (!this.isSpecialProductList ? '/nets' : '/special-products');

    const stateObj = {
      currentPage: this.currentPage,
    };

    const queryString = this.buildQueryString(stateObj);
    history.pushState(stateObj, 'nets', baseUrl + '?' + queryString);
  }

  private sortTable(field) {
    if (!this.netFilters) {
      // filters not set up yet, cannot sort
      return;
    }

    if (this.netFilters.orderBy === field.field) {
      this.netFilters.orderByDirection = this.netFilters.orderByDirection === 'DESC' ? 'ASC' : 'DESC';
    } else {
      this.netFilters.orderBy = field.field;
      this.netFilters.orderByDirection = 'DESC';
    }

    this.pushState();
    this.saveFilters();

    this.getNets();
  }

  private currentPageChanged(newValue, oldValue) {
    logger.debug('this.netFilters', this.netFilters);
    if (!this.netFilters) {
      // cannot change page, netFilters not defined
      return;
    }

    if (parseInt(newValue, 10) === parseInt(oldValue, 10)) {
      return;
    }

    this.pushState();
    logger.debug('newValue ' + newValue);
    this.netFilters.skip = (+newValue - 1) * (this.netFilters.top ?? this.pageSize);
    logger.debug('this.netFilters.skip ' + this.netFilters.skip);
    this.getNets();
  }

  private getFields() {
    return [
      {
        field: 'Id',
        title: 'general.id',
        selected: true,
        disabled: true,
        visible: false,
      },
      {
        field: 'NetIdentifier',
        title: 'net.netidentifier',
        selected: true,
        disabled: false,
      },
      {
        field: 'CustomerNetId',
        title: 'net.customerNetId',
        selected: false,
        disabled: false,
      },
      {
        field: 'CustomerName',
        title: 'general.customer',
        selected: !this.context || this.context.indexOf('customer-list') < 0,
        disabled: false,
      },
      {
        field: 'DepartmentName',
        title: 'general.DepartmentId',
        selected: this.context && this.context.indexOf('customer-list') >= 0,
        disabled: false,
      },
      {
        field: 'NetShapeName',
        title: 'notlogg.netshape',
        selected: !this.isSpecialProductList,
        disabled: false,
        visible: !this.isSpecialProductList,
      },
      {
        field: 'NetTypeName',
        title: 'notlogg.netType',
        selected: !this.isSpecialProductList,
        disabled: false,
        visible: !this.isSpecialProductList,
      },
      {
        field: 'ProducerName',
        title: 'net.producer',
        selected: false,
        disabled: false,
      },
      {
        field: 'DepthBottom',
        title: 'order.depthBottomRope',
        selected: !this.isSpecialProductList,
        disabled: false,
        visible: !this.isSpecialProductList,
      },
      {
        field: 'TotalDepth',
        title: 'netdimension.TotalDepth',
        selected: !this.isSpecialProductList,
        disabled: false,
        visible: !this.isSpecialProductList,
      },
      {
        field: 'Circumference',
        title: 'net.circumference',
        selected: !this.isSpecialProductList,
        disabled: false,
        visible: !this.isSpecialProductList,
      },
      {
        field: 'NettingTypeName',
        title: 'net.nettingTypeName',
        selected: !this.isSpecialProductList,
        disabled: false,
        visible: !this.isSpecialProductList,
      },
      {
        field: 'NettingTypeNameList',
        title: 'List',
        selected: true,
        disabled: false,
        visible: false,
      },
      {
        field: 'MaterialName',
        title: 'general.nettingMaterial',
        selected: !this.isSpecialProductList,
        disabled: false,
        visible: !this.isSpecialProductList,
      },
      {
        field: 'MaterialNameList',
        title: 'net.nettingTypeName',
        selected: true,
        disabled: false,
        visible: false,
      },
      {
        field: 'MeshSize',
        title: 'notlogg.meshsize',
        selected: !this.isSpecialProductList,
        disabled: false,
        visible: !this.isSpecialProductList,
      },
      {
        field: 'ThreadType',
        title: 'nettingtype.threadtype',
        selected: !this.isSpecialProductList,
        disabled: false,
        visible: !this.isSpecialProductList,
      },
      {
        field: 'SpecialProductTypeName',
        title: 'notlogg.specialproducttype',
        selected: this.isSpecialProductList,
        disabled: false,
        visible: this.isSpecialProductList,
      },
      {
        field: 'SpecialProductCircumference',
        title: 'specialProduct.Circumference',
        selected: this.isSpecialProductList,
        disabled: false,
        visible: this.isSpecialProductList,
      },
      {
        field: 'SpecialProductLength',
        title: 'specialProduct.Length',
        selected: this.isSpecialProductList,
        disabled: false,
        visible: this.isSpecialProductList,
      },
      {
        field: 'SpecialProductLengthFavn',
        title: 'general.lengthFavn',
        selected: false,
        disabled: false,
        visible: this.isSpecialProductList,
      },
      {
        field: 'SpecialProductWidth',
        title: 'specialProduct.Width',
        selected: this.isSpecialProductList,
        disabled: false,
        visible: this.isSpecialProductList,
      },
      {
        field: 'SpecialProductWidthFavn',
        title: 'general.widthFavn',
        selected: false,
        disabled: false,
        visible: this.isSpecialProductList,
      },
      {
        field: 'SpecialProductOmfar',
        title: 'specialProduct.omfar',
        selected: this.isSpecialProductList && this.state.user.Industry === 'fishery',
        disabled: false,
        visible: this.isSpecialProductList,
      },
      {
        field: 'DeliveryDate',
        title: 'net.producedDate',
        selected: true,
        isDate: true,
        disabled: false,
      },
      {
        field: 'ValidTo',
        title: 'net.validto',
        selected: true,
        disabled: false,
        isDate: true,
        checkDatePassed: true,
      },
      {
        field: 'DimensionClassName',
        title: 'netdimension.dimensionClass',
        selected: false,
        disabled: false,
        visible: !this.isSpecialProductList,
      },
      {
        field: 'MaxHs',
        title: 'netdimension.maxhs',
        selected: false,
        disabled: false,
        visible: !this.isSpecialProductList,
      },
      {
        field: 'MaxVc',
        title: 'netdimension.maxvc',
        selected: false,
        disabled: false,
        visible: !this.isSpecialProductList,
      },
      {
        field: 'LiftingRopeCount',
        title: 'rope.liftingropecount',
        selected: false,
        disabled: false,
        visible: !this.isSpecialProductList,
      },
      {
        field: 'SideRopeCount',
        title: 'rope.verticalropecount',
        selected: false,
        disabled: false,
        visible: !this.isSpecialProductList,
      },
      {
        field: 'ServiceCount',
        title: 'net.numberOfServices',
        selected: true,
        disabled: false,
      },
      {
        field: 'InternalCommentLastService',
        title: 'net.internalCommentLastService',
        titleShort: 'net.internalCommentLastServiceShort',
        selected: false,
        disabled: false,
      },
      {
        field: 'Created',
        title: 'net.created',
        selected: false,
        disabled: false,
        isDate: true,
        visible: false,
      },
      {
        field: 'AquacomId',
        title: 'net.isInAquacom',
        selected: false,
        disabled: false,
        isDate: false,
      },
      {
        field: 'CustomerAquacomId',
        title: 'net.isInAquacom',
        selected: true,
        disabled: false,
        isDate: false,
        visible: false,
      },
      {
        field: 'NetPosition',
        title: 'net.position',
        selected: this.context === 'customer-list',
        disabled: false,
        visible: !this.isSpecialProductList,
      },
      {
        field: 'MerdNumber',
        title: 'net.merdnumber',
        selected: this.context === 'customer-list',
        disabled: false,
        visible: !this.isSpecialProductList,
      },
      {
        field: 'ApprovedDate',
        title: 'net.approved',
        selected: false,
        disabled: false,
        isDate: true,
        visible: !this.isSpecialProductList,
      },
      {
        field: 'DeliveredRecyclingCompany',
        title: 'service.deliveredRecycling',
        isDate: true,
        selcted: false,
        disabled: false,
      },
      {
        field: 'RecyclingCompanyName',
        title: 'service.recyclingCompany',
        selcted: false,
        disabled: false,
      },
      {
        field: 'NetIntentionName',
        title: 'notlogg.netintent',
        selected: false,
        disabled: false,
        visible: !this.isSpecialProductList,
      },
      {
        field: 'LastApprovedMonths',
        title: 'net.LastApprovedMonths',
        selected: false,
        disabled: false,
      },
      {
        field: 'IsCollect',
        title: 'general.isMorenotCollect',
        disabled: false,
        visible: true,
        selected: false,
      },
      {
        field: 'IsCombiNet',
        title: 'general.combiNet',
        disabled: false,
        visible: true,
        selected: false,
      },
      {
        field: 'NetStatusName',
        title: 'net.status',
        selected: true,
        disabled: false,
      },
      // { field: 'NetStatusId', title: 'net.status.id', selected: true, disabled: false },
    ];
  }

  private async clearFilters() {
    await this.netFilterClearAll();
  }

  private async getNets() {
    if (this.fields == null) {
      this.fields = this.getFields();
    }

    this.selectedFields = this.fields.filter((x) => x.selected && (x.visible === undefined || x.visible === true));

    // Needs to be inside a timeout because query-function
    // is binded in from net-filters
    setTimeout(async () => {
      const netFiltersQuery: any = await this.netFilterGetFiltersQuery(this.fields);

      //Added for test, need to move it after feedback
      let materialName;
      if (netFiltersQuery?.materialIds?.length === 1) {
        const result = await this.materialService.get(netFiltersQuery.materialIds[0]);
        materialName = result.Name;
      }
      //End

      setTimeout(() => {
        if (!this.searchTextSubscription) {
          this.searchTextSubscription = this.bindingEngine
            .propertyObserver(this.netFilters, 'searchText')
            .subscribe((newValue, oldValue) => {
              this.saveFilters();
              this.getNets();
            });
        }

        netFiltersQuery._export = false;
        netFiltersQuery._select = this.fields
          .filter((x) => x.selected)
          .map((x) => x.field)
          .join(',');

        netFiltersQuery.top = this.pageSize;
        netFiltersQuery.skip = this.netFilters.skip;
        netFiltersQuery.orderBy = this.netFilters.orderBy;
        netFiltersQuery.orderByDirection = this.netFilters.orderByDirection;
        netFiltersQuery.specialProductsOnly = this.isSpecialProductList;

        this.setAdditionalFilters(netFiltersQuery);

        this.netService
          .getList(netFiltersQuery)
          .then((res) => {
            this.ready = true;

            this.totalItems = res.headers.get('x-total-count');

            return res.text().then((responseText) => {
              this.tableData = JSON.parse(responseText);

              //Added for test, need to move it after feedback
              this.tableData.forEach((row) => {
                const uniqueArray = [];
                const materialNameListArray = row.MaterialNameList.split(', ');

                materialNameListArray.forEach((element) => {
                  if (!uniqueArray.includes(element)) {
                    uniqueArray.push(element);
                  }
                });

                row.MaterialNameArray = materialName ? [materialName] : uniqueArray;
                row.MaterialNameList = uniqueArray.join(', ').toString();

                if (netFiltersQuery?.materialIds?.length === 1) {
                  const nettingArrray = row.NettingTypeNameList.split(', ');
                  const filteredArray = nettingArrray.filter((item) => item.includes(materialName));

                  row.NettingTypeName = filteredArray;
                }
              });
            });
            //End
          })
          .catch((err) => {
            this.ready = true;
            this.errorService.handleError(err);
          });
      });
    });
  }

  private getNetTypes(): Promise<any> {
    return this.netTypeService
      .getAllCached()
      .then((res) => {
        this.netTypes = res.filter((it: any) => it.IsFilterable);

        return this.netTypes;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getSpesialProductTypes(): Promise<any> {
    return this.specialProductTypeService
      .getAllCached()
      .then((res) => {
        this.specialProductTypes = res.filter((x) => !x.IsDeleted);

        return this.specialProductTypes;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private async exportNetList(exportOnlyVisible = false) {
    try {
      if (this.exportInProgress) {
        return;
      }
      this.exportInProgress = true;

      const netFiltersQuery: any = await this.netFilterGetFiltersQuery(this.fields);

      if (this.isSpecialProductList) {
        netFiltersQuery.specialProductsOnly = this.isSpecialProductList;
      }

      this.setAdditionalFilters(netFiltersQuery);

      const visibleFields = this.fields
        .filter((x: Field) => x.selected && x.visible != false)
        .map((x: Field) => x.field);
      netFiltersQuery._visibleFields = visibleFields.join(',');
      netFiltersQuery._exportOnlyVisibleFields = exportOnlyVisible;

      netFiltersQuery._select = this.fields
        .filter((x: Field) => x.exportable != false && x.disabled != true)
        .map((x: Field) => x.field)
        .join(',');
      netFiltersQuery._export = true;

      await this.netService.getList(netFiltersQuery);
      this.ready = true;
    } catch (error) {
      this.errorService.handleError(error);
    }
    this.exportInProgress = false;
  }

  private async exportReport() {
    const netFiltersQuery: any = await this.netFilterGetFiltersQuery(this.fields);
    this.setAdditionalFilters(netFiltersQuery);

    await this.netService.exportDetailedReport(netFiltersQuery);
  }

  private setAdditionalFilters(netFiltersQuery) {
    if (!!this.customerid) {
      netFiltersQuery.customerId = this.customerid;
    }
    if (!!this.netFilters.searchText) {
      netFiltersQuery.searchText = this.netFilters.searchText;
    }
  }

  private dateHasPassed(field, date) {
    if (!field.checkDatePassed) {
      return false;
    }

    const now: Date = new Date();
    date = new Date(date);

    if (date < now) {
      return true;
    } else {
      return false;
    }
  }

  private saveFilters() {
    const data: any = {};

    for (const [key, value] of Object.entries(this.netFilters)) {
      const filter = this.netFilters[key];

      if (!filter || typeof filter === 'undefined') {
        continue;
      } else if (typeof filter === 'string' || typeof filter === 'number') {
        data[key] = filter;
      } else {
        data[key] = {
          values: filter.values,
          visible: filter._visible ?? false,
        };
      }
    }

    data.orderBy = this.netFilters.orderBy;
    data.orderByDirection = this.netFilters.orderByDirection;
    data.top = this.netFilters.top ?? this.pageSize;
    data.skip = this.netFilters.skip;

    localStorage.setItem(this.getFilterKey(), JSON.stringify(data));
  }

  private updateSelectList(event, field) {
    const selectedFields = this.fields.filter((x) => x.selected).map((x) => x.field);
    localStorage.setItem(
      SELECTED_NET_COLUMNS + (this.context ? '_' + this.context : ''),
      JSON.stringify(selectedFields)
    );

    this.getNets();
  }

  protected async downloadAttachment(netId: number, attachmentId: number) {
    const netInfo = await this.netService.getNetInfo(netId);
    try {
      await this.fileService.download(attachmentId, netInfo.CustomerId);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }
}
