import { NetService } from 'services/net-service';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { ValidationController, ValidationRules, Validator, validateTrigger } from 'aurelia-validation';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';
import { Router } from 'aurelia-router';

@autoinject
export class NetCopy {
  private net: Models.Net;

  formIsValid = false;
  isCopying = false;

  copydata: { NewNetIdentifier?: string; ExistingNetId: number; Prefix?: string };

  constructor(
    private netService: NetService,
    private errorService: ErrorService,
    private i18n: I18N,
    private toastService: ToastService,
    private validationController: ValidationController,
    private validator: Validator,
    private router: Router
  ) {}

  async activate(params: { Id: string | number }) {
    this.validationController.validateTrigger = validateTrigger.changeOrBlur;
    await this.getExistingNets(params.Id);
  }

  get newNetIdentifier() {
    return `${this.copydata.Prefix?.toUpperCase()} - ${this.copydata.NewNetIdentifier}`;
  }

  async getExistingNets(netId: number | string) {
    try {
      const net = await this.netService.get(netId);
      this.net = net;
      this.copydata = {
        ExistingNetId: this.net.Id,
        Prefix: undefined,
        NewNetIdentifier: undefined,
      };

      ValidationRules.ensure('NewNetIdentifier')
        .required()
        .withMessage(this.i18n.tr('general.requiredField'))
        .ensure('Prefix')
        .required()
        .withMessage(this.i18n.tr('general.requiredField'))
        .on(this.copydata);

      this.copydata.NewNetIdentifier = '';
      const seperatorIndex = this.net.NetIdentifier.indexOf('-');
      const spaceIndex = this.net.NetIdentifier.indexOf(' ');
      if (seperatorIndex > 0 || spaceIndex > 0) {
        const prefix = this.net.NetIdentifier.substring(0, seperatorIndex > 0 ? seperatorIndex : spaceIndex);
        this.copydata.Prefix = prefix;
      } else {
        this.copydata.Prefix = this.net.NetIdentifier;
      }

      this.validationController.subscribe((event) => {
        if (event.type === 'reset') return;
        this.formIsValid = event.errors.length === 0 && this.copydata.NewNetIdentifier?.length > 0;
      });
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  async copyNet() {
    if (!this.copydata.NewNetIdentifier) {
      this.toastService.showError('net.copynonewidentifier');
      return;
    }

    this.isCopying = true;

    const duplicates = await this.netService.checkNetIdentifiers([this.copydata.NewNetIdentifier]);

    if (!!duplicates && duplicates.length !== 0) {
      this.toastService.showError('net.copyNetAlreadyExistingIdentifiers');
      this.isCopying = false;
      return;
    }

    try {
      const result = await this.netService.copyNet({
        ExistingNetId: this.copydata.ExistingNetId,
        NewNetIdentifier: `${this.copydata.Prefix.trim()} - ${this.copydata.NewNetIdentifier.trim()}`,
      });
      this.isCopying = false;
      this.toastService.show('info', 'net.netcopied');

      this.router.navigateToRoute('net-detail', { Id: result.Id });
    } catch (error) {
      this.errorService.handleError(error);
    }
    this.isCopying = false;
  }
}
