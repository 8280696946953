import { AuthorizeStep } from './../../authorizeStep';
import { HistoryDialog } from './../history-dialog/history-dialog';
import { DialogCloseResult, DialogService } from 'aurelia-dialog';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { PLATFORM } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { NavigationInstruction, Next, Redirect, Router, RouterConfiguration } from 'aurelia-router';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { NetService } from 'services/net-service';
import { ReportService } from 'services/report-service';
import { ToastService } from 'services/toast-service';
import { NetOfferService } from 'services/net-offer-service';
import tippy from 'tippy.js';
import { StorageHistoryService } from 'services/storage-history-service';
import { ServiceStationService } from 'services/service-station-service';
import { StorageSelectionDialog } from 'components/dialogs/storage-selection/storage-selection-dialog';

@autoinject
export class NetDetailMain {
  private net: Models.Net;
  private locked: boolean = false;
  private downloadNetCertificateProgress: boolean = false;
  private downloadProductionOrderProgress: boolean = false;
  private subscription: Subscription;
  private recalculateSubscription: Subscription;
  private userIsAdmin: boolean = false;
  protected is2021Net = false;

  private isEditingImportantComment: boolean = false;
  private importantComment: string;

  private storageLocation: {
    serviceStationName: string;
    serviceStationId: number;
    storageName: string;
    storageId: number;
  };

  constructor(
    private netService: NetService,
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private reportService: ReportService,
    private dialogService: DialogService,
    private storageHistoryService: StorageHistoryService,
    private serviceStationService: ServiceStationService,
    private netOfferService: NetOfferService
  ) {}

  private configureRouter(config: RouterConfiguration, router) {
    config.map([
      {
        route: ['', 'dimensions'],
        name: 'net-detail-dimension',
        moduleId: PLATFORM.moduleName('../offer/form-dimension'),
        tabindex: 0,
        entityShortName: 'net',
      },
      {
        route: ['netting'],
        name: 'net-detail-netting',
        moduleId: PLATFORM.moduleName('../offer/form-netting'),
        tabindex: 1,
        entityShortName: 'net',
      },
      {
        route: ['rope'],
        name: 'net-detail-rope',
        moduleId: PLATFORM.moduleName('../offer/form-rope'),
        tabindex: 2,
        entityShortName: 'net',
      },
      {
        route: ['misc'],
        name: 'net-detail-misc',
        moduleId: PLATFORM.moduleName('../offer/form-misc'),
        tabindex: 3,
        entityShortName: 'net',
      },
      {
        route: ['center-bottom'],
        name: 'simple-offer-tab-center-bottom',
        moduleId: PLATFORM.moduleName('../simple-offer/simple-offer-tab-center-bottom'),
        tabindex: 4,
        entityShortName: 'net',
      },
      {
        route: ['extra-equipment'],
        name: 'simple-offer-tab-extra-equipment',
        moduleId: PLATFORM.moduleName('../simple-offer/simple-offer-tab-extra-equipment'),
        tabindex: 5,
        entityShortName: 'net',
      },
      {
        route: ['impregnation'],
        name: 'net-detail-impregnation',
        moduleId: PLATFORM.moduleName('../offer/form-impregnation'),
        tabindex: 6,
        entityShortName: 'net',
      },
      {
        route: ['preparation'],
        name: 'net-detail-preparation',
        moduleId: PLATFORM.moduleName('../offer/form-preparation'),
        tabindex: 7,
        entityShortName: 'net',
      },
      {
        route: ['production'],
        name: 'net-detail-production',
        moduleId: PLATFORM.moduleName('./net-detail-production'),
        tabindex: 8,
        entityShortName: 'net',
      },
      {
        route: ['info'],
        name: 'net-detail-info',
        moduleId: PLATFORM.moduleName('./net-detail-services'),
        tabindex: 9,
        entityShortName: 'net',
      },

      {
        route: ['certification'],
        name: 'net-detail-certification',
        moduleId: PLATFORM.moduleName('./net-detail-certification'),
        tabindex: 10,
        entityShortName: 'net',
      },
    ]);
  }

  private activate(params) {
    this.getNet(params.Id);
    void this.getStorageLocation(params.Id);
    this.subscription = this.eventAggregator.subscribe('netLocked', (value) => {
      this.getNet(params.Id);
    });

    // hack to recalculate length/weight for order, should refactor this later, but
    // need to get a quick solution in place
    this.recalculateSubscription = this.eventAggregator.subscribe('offer-refresh', (e) => {
      switch (e) {
        case 'price':
          if (this.net && this.net.IsMorenotNet && !this.net.ApprovedDate) {
            this.recalculateNet(this.net.Id);
          }
          break;

        default:
          break;
      }
    });

    if (!AuthorizeStep.auth.roles) {
      setTimeout(() => {
        if (AuthorizeStep.auth.roles && AuthorizeStep.auth.roles.indexOf('Administrator') !== -1) {
          this.userIsAdmin = true;
        }
      }, 1000);
    } else {
      if (AuthorizeStep.auth.roles && AuthorizeStep.auth.roles.indexOf('Administrator') !== -1) {
        this.userIsAdmin = true;
      }
    }
  }

  private async getStorageLocation(netId?: number) {
    const storage = await this.storageHistoryService.getNetStorage(this.net?.Id || netId);
    if (storage) {
      const station = await this.serviceStationService.get(storage.ServiceStationId);
      if (station) {
        this.storageLocation = {
          serviceStationId: station.Id,
          serviceStationName: station.Name,
          storageName: storage.Name,
          storageId: storage.Id,
        };
      }
    } else {
      this.storageLocation = undefined;
    }
  }

  private detached() {
    if (this.subscription) {
      this.subscription.dispose();
    }

    if (this.recalculateSubscription) {
      this.recalculateSubscription.dispose();
    }
  }

  private deactivate() {
    if (this.subscription) {
      this.subscription.dispose();
    }

    if (this.recalculateSubscription) {
      this.recalculateSubscription.dispose();
    }
  }

  private recalculateNet(netId: number) {
    this.netService
      .recalculateData(netId)
      .then((res) => {
        this.eventAggregator.publish('refresh-calculated-dimensions');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getNet(id) {
    this.netService
      .get(id + '?$expand=NetDimension($expand=NetStandard)')
      .then((res) => {
        this.is2021Net = res.NetDimension.NetStandard?.Version === Models.NetStandardVersion.NS9415_2021;
      })
      .catch((err) => this.errorService.handleError(err));
    this.netService
      .getNetInfo(id)
      .then((res) => {
        this.net = res;
        this.locked = res.Locked ? res.Locked : false;

        setTimeout(() => {
          tippy('.tippy', {
            content: (reference) => {
              const title = reference.getAttribute('title');
              reference.removeAttribute('title');
              return title;
            },
          });
        });
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private regenerateNetCertificate() {
    this.dialogService
      .open({
        viewModel: Prompt,
        model: {
          header: this.net.CanGenerateCertificate
            ? 'net.regenerateCertificateHeader'
            : 'net.regenerateSpecificationHeader',
          message: this.net.CanGenerateCertificate
            ? 'net.regenerateCertificateText'
            : 'net.regenerateSpecificationText',
          actions: {
            continue: {
              enabled: true,
              t: this.net.CanGenerateCertificate ? 'net.RegenerateCertificate' : 'net.RegenerateSpecification',
            },
            cancel: { enabled: true, t: 'dialog.cancel' },
          },
        },
      })
      .whenClosed((res) => {
        if (!res.wasCancelled) {
          this.netService
            .regenerateCertificate(this.net.Id)
            .then((res) => {
              this.toastService.showSuccess('net.certificateRegenerated');

              // download certificate when new document is generated
              this.downloadNetCertificate();
            })
            .catch((err) => this.errorService.handleError(err));
        }
      });
  }

  private downloadNetCertificate() {
    if (this.downloadNetCertificateProgress) {
      return;
    }
    this.downloadNetCertificateProgress = true;
    this.reportService
      .getCertificateReport(this.net.Id)
      .then((res) => {
        this.toastService.showSuccess('document.downloaded');
        this.downloadNetCertificateProgress = false;
      })
      .catch((err) => {
        this.errorService.handleError(err);
        this.downloadNetCertificateProgress = false;
      });
  }

  private unlockNet() {
    this.netService
      .unlockNet(this.net.Id)
      .then((res) => {
        this.toastService.showSuccess('net.unlocked');
        window.location.reload();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private createService() {
    this.router.navigateToRoute('service-new', { NetId: this.net.Id });
  }

  private extendValidity() {
    this.router.navigateToRoute('service-new', { NetId: this.net.Id, extendValidity: true });
  }

  private deleteNet() {
    return this.dialogService
      .open({
        viewModel: Prompt,
        model: {
          header: 'dialog.deleteNetHeader',
          message: 'dialog.deleteNetText',
          actions: {
            delete: { enabled: true, t: 'dialog.deleteNet' },
            save: { enabled: false },
            cancel: { enabled: true, t: 'dialog.cancel' },
            dontsave: { enabled: false },
            continue: { enabled: false },
          },
        },
      })
      .whenClosed((response) => {
        if (response.wasCancelled) {
          return false;
        } else {
          const result = response.output;
          if (result === 'delete') {
            this.netService
              .delete(this.net.Id)
              .then((res) => {
                this.toastService.showSuccess('net.successfullyDeleted');
                this.router.navigateToRoute('net-list');
              })
              .catch((err) => this.errorService.handleError(err));
            return false;
          } else {
            return true;
          }
        }
      });
  }

  private cloneNet() {
    this.netService
      .getNetInfo(this.net.Id)
      .then((res) => {
        if ((<any>res).NetOfferId) {
          this.router.navigateToRoute('offer-new', { CopyNetDesignFromExistingNetId: this.net.Id });
        } else {
          // if the net was not created through a netoffer, it is an old net created in the old notlogg.
          // if so, notify the user that details on the net might need to be updated before continuing
          return this.dialogService
            .open({
              viewModel: Prompt,
              model: {
                header: 'dialog.copyOldNetHeader',
                message: 'dialog.copyOldNetText',
                actions: {
                  delete: { enabled: false },
                  save: { enabled: false },
                  cancel: { enabled: true, t: 'dialog.cancel' },
                  dontsave: { enabled: false },
                  continue: { enabled: true, t: 'dialog.continueCopy' },
                },
              },
            })
            .whenClosed((response) => {
              const result = response.output;
              if (result === 'continue') {
                this.router.navigateToRoute('offer-new', { CopyNetDesignFromExistingNetId: this.net.Id });
              }
            });
        }
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private downloadProductionOrder() {
    if (this.downloadProductionOrderProgress) {
      return;
    }

    this.downloadProductionOrderProgress = true;
    this.reportService
      .getProductionOrderReport(this.net.Id)
      .then((res) => {
        this.toastService.showSuccess('document.downloaded');
        this.downloadProductionOrderProgress = false;
      })
      .catch((err) => {
        this.errorService.handleError(err);
        this.downloadProductionOrderProgress = false;
      });
  }

  private editImportantComment() {
    this.importantComment = this.net.ImportantComment;
    this.isEditingImportantComment = true;
  }

  private cancelEditImportantComment() {
    this.isEditingImportantComment = false;
  }

  private saveImportantComment() {
    this.net.ImportantComment = this.importantComment;

    this.netService
      .updateImportantComment(this.net.Id, this.net.ImportantComment)
      .then((res) => {
        this.toastService.showSuccess('net.importantCommentUpdated');
        this.isEditingImportantComment = false;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  protected editStorage() {
    this.dialogService
      .open({
        viewModel: StorageSelectionDialog,
        model: {
          netId: this.net?.Id,
          storageLocation: this.storageLocation,
        },
        lock: false,
        position: () => {
          //
        },
      })
      .whenClosed((response: DialogCloseResult) => {
        void this.getStorageLocation(this.net.Id);
        if (response.wasCancelled) return false;
        document.querySelector('html').style.overflowY = null;
      })
      .catch((err) => {
        document.querySelector('html').style.overflowY = null;
        this.errorService.handleError(err);
      });
  }

  protected showHistory() {
    const viewModel = {
      EntityType: 'Net',
      EntityId: this.net.Id,
    };

    void this.dialogService.open({
      viewModel: HistoryDialog,
      model: viewModel,
      lock: false,
      position: () => {
        /**/
      },
    });
  }
}
