import { HttpClient } from 'aurelia-fetch-client';
import { bindable } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { CustomerService } from 'services/customer-service';
import { DepartmentService } from 'services/department-service';
import { ErrorService } from 'services/error-service';
import { NetService } from 'services/net-service';
import { ProducerService } from 'services/producer-service';
import { SiteService } from 'services/site-service';
import { DialogService } from 'aurelia-dialog';
import { I18N } from 'aurelia-i18n';
import { PubSub } from 'lib/event/PubSub';
import * as moment from 'moment';

@autoinject
export class NetFormBasic {
  @bindable private net: any;
  @bindable private customers: Array<Models.Customer>;

  private producers: Array<Models.Producer>;

  constructor(
    private t: I18N,
    private customerService: CustomerService,
    private departmentService: DepartmentService,
    private errorService: ErrorService,
    private netService: NetService,
    private pubsub: PubSub,
    private producerService: ProducerService,
    private siteService: SiteService,
    private dialogService: DialogService
  ) {}

  private attached() {
    this.customerService
      .getAll('?$orderby=Name')
      .then((res) => {
        this.customers = res;
      })
      .catch((err) => this.errorService.handleError(err));

    this.pubsub.sub('date-change', (data) => {
      this.dateConfirmation(data);
    });
  }

  private dateConfirmation(date: Date) {
    let oneYearAgo = moment.default().subtract(1, 'year').toDate();
    if (date > oneYearAgo) {
      return this.dialogService
        .open({
          viewModel: Prompt,
          model: {
            header: 'general.pleaseconfirm',
            message: this.t.tr('general.netDateConfirmation'),
            messagePreparsed: true,
            actions: {
              continue: { enabled: true, t: 'general.yes' },
              cancel: { enabled: true, t: 'general.no' },
            },
          },
        })
        .whenClosed((response) => {
          if (response.wasCancelled) {
            this.net.DeliveryDate = null;
          }
          if (response.output === 'continue') {
            const paranetElement = document.getElementById('DeliveryDate').parentElement;
            if (paranetElement.classList.contains('form-element--has-error')) {
              paranetElement.classList.remove('form-element--has-error');
            }
          }
        });
    }
  }

  private getProducers(): Promise<Array<Models.Producer>> {
    return this.producerService.getAll('?$filter=IsMorenotCompany eq false').then((res) => {
      this.producers = res;
      return res;
    });
  }

  private clearNetIdentifier(value) {
    this.net.CustomerId = null;
  }

  private setCustomer(value) {
    if (!value || value === undefined || value === '' || value === this.net.CustomerId) {
      return;
    } else {
      this.net.CustomerId = value;
    }
  }

  private setProducer(producerId) {
    this.net.ProducerId = producerId;

    this.setNetIdentifier(null);
  }

  private setNetIdentifier(event) {
    if (this.net.Id) {
      return;
    }

    this.net._netIdentifierPrefix = null;
    this.net.NetIdentifier = null;

    if (this.net.ProducerId) {
      let producer = this.producers.find((x) => x.Id == this.net.ProducerId);
      this.net._netIdentifierPrefix = producer.Prefix;
    }

    if (
      this.net.ProducerId &&
      this.net._netIdentifierNumeric &&
      this.net._netIdentifierNumeric &&
      this.net._netIdentifierNumeric.toString() !== ''
    ) {
      if (this.net._netIdentifierPrefix) {
        this.net.NetIdentifier = this.net._netIdentifierPrefix + ' - ' + this.net._netIdentifierNumeric;
      }
    }
  }

  protected detached() {
    this.pubsub.unsub();
  }
}
