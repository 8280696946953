import { ServiceService } from 'services/service-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { NetService } from 'services/net-service';
import { HistoryService } from 'services/history-service';

@autoinject
export class NetDetailServices {
  private net: Models.Net;
  private historyItems: Array<Models.HistoryItem>;
  private aquacomAttachments: Array<any>;
  private customerid: number;
  private nextTabIndex: number = null;
  private subscription: Subscription;
  private services: Models.Service[] = [];

  private defaultColumns = [
    'Id',
    'NetId',
    'ServiceStationName',
    'ReceivedDate',
    'PlannedDeliveryDate',
    'WashedDate',
    'TestedDate',
    'RepairedDate',
    'AntifouledDate',
    'DeliveredDate',
    'StorageName',
    'ServiceStatusId',
    'ServiceStatusName',
  ];

  private defaultFilters = {
    orderBy: 'StatusSortIndexThenByReceivedDate',
    orderByDirection: 'ASC',
  };

  constructor(
    private netService: NetService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private serviceService: ServiceService,
    private historyService: HistoryService
  ) {}

  async activate(params: { Id: number }) {
    this.getNet(params.Id);

    await this.getServices(params.Id);

    localStorage.setItem(
      this.serviceService.SELECTED_SERVICE_COLUMNS + '_net-services',
      JSON.stringify(this.defaultColumns)
    );

    localStorage.setItem(
      this.serviceService.SERVICE_FILTERS_KEY + '_net-services',
      JSON.stringify(this.defaultFilters)
    );

    // refresh if locked / unlocked
    this.subscription = this.eventAggregator.subscribe('netLocked', () => {
      this.getNet(params.Id);
    });

    // change tab
    this.eventAggregator.subscribe('changeTab', (tabIndex: number) => {
      this.nextTabIndex = tabIndex;
    });
  }

  private deactivate() {
    if (this.subscription) {
      this.subscription.dispose();
    }
  }

  private async getServices(netId: number) {
    this.services = (await this.serviceService.get(
      `?$filter=NetId eq ${netId}&$expand=ServiceStation`
    )) as unknown as Models.Service[];
  }

  private getNet(id: number) {
    this.netService
      .getNetInfo(id)
      .then((res) => {
        this.net = res;
        this.customerid = res.CustomerId;
        if (this.net.AquacomId) {
          this.getRemarks(this.net.Id);

          if (this.net.IsMorenotNet || (this.net as any).ServiceCount > 0) {
            this.getAttachments(this.net.Id);
          }
        }
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getRemarks(id) {
    this.historyService
      .getByNet(id)
      .then((res) => {
        this.historyItems = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getAttachments(id) {
    this.netService
      .getAquacomFilesForNet(id)
      .then((res) => {
        this.aquacomAttachments = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private canDeactivate() {
    this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
    return true;
  }

  private downloadAttachment(attachment) {
    this.netService
      .downloadAquacomFile(this.net.Id, attachment.Id)
      .then((res) => {
        // handled by service
      })
      .catch((err) => this.errorService.handleError(err));
  }
}
