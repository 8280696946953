import { DialogController, DialogService } from 'aurelia-dialog';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { DesignTypeService } from 'services/design-type-service';
import { ErrorService } from 'services/error-service';
import { LaceThreadService } from 'services/lace-thread-service';
import { LoopService } from 'services/loop-service';
import { NetIntentService } from 'services/net-intent-service';
import { NetProductionDataService } from 'services/net-production-data-service';
import { NetService } from 'services/net-service';
import { NetShapeService } from 'services/net-shape-service';
import { ReportService } from 'services/report-service';
import { RopeService } from 'services/rope-service';
import { ToastService } from 'services/toast-service';
import { Utility } from '../../utility';

@autoinject
export class NetDetailProduction {
  private net: Models.Net;
  private netInfo: any;
  private netproductionCalculated: Models.NetProductionData;
  private productionclassType: any = 0;
  private ropes: Array<Models.Rope>;
  private loops: Array<Models.Loop>;

  private nextTabIndex: number = null;
  private originalObject: any = {};
  private netproduction: Models.NetProductionData;
  private productionropes: Array<Models.PreparationRope>;
  private lacethreads: Array<Models.LaceThread>;
  private laceThreadNewFormVisible: boolean = false;
  private laceThreadEditFormVisible = null;
  private approved: boolean = false;
  private locked: boolean = false;
  private downloadProductionOrderProgress: boolean = false;

  private subscription: Subscription;
  laceThreadNames: string[];
  laceThreadTypes: string[];
  laceThreadDimansions: string[];

  constructor(
    private netService: NetService,
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private designTypeService: DesignTypeService,
    private netShapeService: NetShapeService,
    private netIntentService: NetIntentService,
    private netProductionService: NetProductionDataService,
    private ropeService: RopeService,
    private loopService: LoopService,
    private dialogService: DialogService,
    private utility: Utility,
    private laceThreadService: LaceThreadService,
    private reportService: ReportService,
    private i18n: I18N
  ) {}

  private activate(params) {
    this.getNet(params.Id);

    let language = this.i18n.getLocale();

    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.eventAggregator.subscribe('changeTab', (tabIndex) => {
      this.nextTabIndex = tabIndex;
    });

    // Update NetProduction when creating new laceThreads
    this.subscription = this.eventAggregator.subscribe(
      'laceThreadListReset',
      (value) => {
        this.laceThreadEditFormVisible = null;
        this.getLaceThreads();
      }
    );

    this.eventAggregator.subscribe('laceThreadFormEditClose', (value) => {
      this.laceThreadEditFormVisible = null;
    });

    this.eventAggregator.subscribe('laceThreadFormNewClose', (value) => {
      this.laceThreadNewFormVisible = false;
    });
  }

  private deactivate() {
    if (this.subscription) {
      this.subscription.dispose();
    }
  }

  private canDeactivate() {
    this.net = this.utility.areEqualDateFix(this.net, [
      { key: 'DeliveryDate' },
    ]);
    this.originalObject.net = this.utility.areEqualDateFix(
      this.originalObject.net,
      [{ key: 'DeliveryDate' }]
    );

    if (
      !this.net.Locked &&
      this.originalObject &&
      (!this.utility.areEqual(
        this.netproduction,
        this.originalObject.netproduction,
        true
      ) ||
        !this.utility.areEqual(this.net, this.originalObject.net, true) ||
        this.laceThreadEditFormVisible != null ||
        this.laceThreadNewFormVisible === true)
    ) {
      return this.dialogService
        .open({
          viewModel: Prompt,
          model: {
            header: 'dialog.pleaseConfirmHeader',
            message: 'dialog.unsavedChangesText',
          },
        })
        .whenClosed((response) => {
          if (response.wasCancelled) {
            return false;
          } else {
            const result = response.output;
            if (result === 'save') {
              // save the nettingtype and let that function handle the rest of the logic
              this.updateNetProduction();
              return false;
            } else {
              this.eventAggregator.publish(
                'changeTab-success',
                this.nextTabIndex
              );
              return true;
            }
          }
        });
    } else {
      this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
      return true;
    }
  }

  // Get net and netproduction
  private getNet(id) {
    this.netService
      .get(id)
      .then((res) => {
        this.net = res;
        this.locked = res.Locked ? res.Locked : false;
        this.updateOriginalObject();

        this.netService
          .getNetInfo(id)
          .then((netInfo) => (this.netInfo = netInfo));

        this.getNetProduction(res.NetProductionDataId);
        this.getLaceThreads();
      })
      .catch((err) => this.errorService.handleError(err));

    this.getSignature(id);
  }

  private cancel() {
    this.router.navigateToRoute('net-list');
  }

  private deleteLaceThread(id) {
    this.laceThreadService
      .delete(id)
      .then((res) => {
        this.toastService.showSuccess('productionrope.deleted');
        this.eventAggregator.publish('laceThreadListReset', 1);
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private updateNetProduction() {
    this.netProductionService
      .put(this.netproduction, this.netproduction.Id)
      .then((res) => {
        this.toastService.showSuccess('netproduction.updated');
        this.updateOriginalObject();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getNetProduction(id) {
    this.netProductionService
      .get(id)
      .then((res) => {
        this.netproduction = res;
        this.updateOriginalObject();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getLaceThreads() {
    this.laceThreadService
      .getAll(
        '?$filter=NetId eq ' +
          this.net.Id +
          ' &$expand=LaceThreadCategory,LaceThreadDimension,LaceThreadType'
      )
      .then((res) => {
        this.laceThreadNames = res.map((lt) => {
          switch (this.i18n.getLocale()) {
            case 'no':
            case 'nb-NO':
            case 'nb':
              return lt.LaceThreadCategory?.Name;
            case 'es':
              return lt.LaceThreadCategory?.NameEs;
            default:
              return lt.LaceThreadCategory?.NameEn;
          }
        });

        this.laceThreadDimansions = res.map((lt) => {
          switch (this.i18n.getLocale()) {
            case 'no':
            case 'nb-NO':
            case 'nb':
              return lt.LaceThreadDimension?.Name;
            case 'es':
              return lt.LaceThreadDimension?.NameEs;
            default:
              return lt.LaceThreadDimension?.NameEn;
          }
        });

        this.laceThreadTypes = res.map((lt) => {
          switch (this.i18n.getLocale()) {
            case 'no':
            case 'nb-NO':
            case 'nb':
              return lt.LaceThreadType?.Name;
            case 'es':
              return lt.LaceThreadType?.NameEs;
            default:
              return lt.LaceThreadType?.NameEn;
          }
        });

        this.lacethreads = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getSignature(id) {
    this.netService
      .get(id)
      .then((res) => {
        this.approved = res.Signature != null ? true : false;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private approve() {
    // check for date
    if (!this.net.DeliveryDate) {
      return this.toastService.showError('netproduction.dateRequired');
    }

    // we bypass DeliveryDate in areEqual-check
    this.originalObject.net.DeliveryDate = this.net.DeliveryDate;

    // date-fix
    this.net = this.utility.areEqualDateFix(this.net, [
      { key: 'DeliveryDate' },
    ]);
    this.originalObject.net = this.utility.areEqualDateFix(
      this.originalObject.net,
      [{ key: 'DeliveryDate' }]
    );

    // check if some changes are missing
    if (
      this.originalObject &&
      (!this.utility.areEqual(
        this.netproduction,
        this.originalObject.netproduction,
        true
      ) ||
        !this.utility.areEqual(this.net, this.originalObject.net, true) ||
        this.laceThreadEditFormVisible != null ||
        this.laceThreadNewFormVisible === true)
    ) {
      return this.toastService.showError('dialog.unsavedChangesText');
    }

    return this.dialogService
      .open({
        viewModel: Prompt,
        model: {
          header: 'dialog.publishAndLockHeader',
          message: this.netInfo.CanGenerateCertificate
            ? 'dialog.publishAndLockText'
            : 'dialog.publishAndLockTextSpecification',
          actions: {
            delete: { enabled: false },
            save: { enabled: false },
            cancel: { enabled: true, t: 'dialog.cancel' },
            dontsave: { enabled: false },
            continue: { enabled: true, t: 'dialog.publishAndLock' },
          },
        },
      })
      .whenClosed((response) => {
        if (response.wasCancelled) {
          return false;
        } else {
          const result = response.output;
          if (result === 'continue') {
            // remove expandable objects
            delete this.net.Customer;
            delete this.net.LaceThreads;

            this.netService
              .get(this.net.Id)
              .then((netObject) => {
                netObject.DeliveryDate = this.net.DeliveryDate;

                // update net (add delivery-date)
                this.netService
                  .put(netObject, this.net.Id)
                  .then((res) => {
                    // sign and lock
                    this.netService
                      .approveNet(this.net.Id)
                      .then((ress) => {
                        this.eventAggregator.publish('netLocked');
                        this.toastService.showSuccess('net.approved');
                        window.location.reload();
                      })
                      .catch((err) => this.errorService.handleError(err));
                  })
                  .catch((err) => this.errorService.handleError(err));
              })
              .catch((err) => this.errorService.handleError(err));

            return false;
          } else {
            return true;
          }
        }
      });
  }

  private downloadProductionOrder() {
    if (this.downloadProductionOrderProgress) {
      return;
    }

    this.downloadProductionOrderProgress = true;
    this.reportService
      .getProductionOrderReport(this.net.Id)
      .then((res) => {
        this.toastService.showSuccess('document.downloaded');
        this.downloadProductionOrderProgress = false;
      })
      .catch((err) => {
        this.errorService.handleError(err);
        this.downloadProductionOrderProgress = false;
      });
  }

  private updateOriginalObject() {
    if (this.net) {
      this.originalObject.net = JSON.parse(JSON.stringify(this.net));
    }
    if (this.netproduction) {
      this.originalObject.netproduction = JSON.parse(
        JSON.stringify(this.netproduction)
      );
    }
  }
}
