import { CustomerService } from 'services/customer-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import { Models } from 'models/core';
import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from 'services/error-service';
import { NetService } from 'services/net-service';
import { ToastService } from 'services/toast-service';

@autoinject
export class NetNew {
  private net: Models.Net;
  private originalCustomerId: number;
  private originalCustomerNetId: string;

  constructor(
    private deleteDialogService: DeleteDialogService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private i18n: I18N,
    private netService: NetService,
    private customerService: CustomerService,
    private router: Router,
    private toastService: ToastService,
    private validationController: ValidationController,
    private validator: Validator
  ) {}

  private activate(params) {
    this.netService
      .get(params.Id)
      .then((net) => {
        this.net = net;

        this.originalCustomerId = net.CustomerId;
        this.originalCustomerNetId = net.CustomerNetId;

        ValidationRules
          .ensure('NetIdentifier').required().withMessage(this.i18n.tr('general.requiredField'))
          .on(this.net);

      })
      .catch((err) => this.errorService.handleError(err));
  }

  private async validateBeforeSave() {
    if (this.net.CustomerId != this.originalCustomerId) {
      try
      {
        const canChangeCustomerResult = await this.netService.checkCanChangeCustomer(this.net.Id, this.net.CustomerId);
        if (!canChangeCustomerResult.CanChange) {
          this.toastService.showError('net.cannotChangeCustomer');
          return;
        }
      } catch (err) {
        this.errorService.handleError(err);
        return;
      }
    }

    if (this.net.CustomerNetId && this.net.CustomerNetId != this.originalCustomerNetId && !this.net.IsSpecialProduct) {
      try
      {
        const canChangeCustomerNetIdResult = await this.netService.checkCanChangeCustomerNetId(this.net.Id, this.net.CustomerNetId);
        if (!canChangeCustomerNetIdResult.CanChange) {
          this.toastService.showError('net.cannotChangeCustomerNetId');
          return;
        }
      } catch (err) {
        this.errorService.handleError(err);
        return;
      }
    }


    // MANUAL VALIDATION
    this.validator.validateObject(this.net)
      .then((result) => {
        const errors = result.filter((validateResult) => {
          return !validateResult.valid;
        });
        if (errors.length > 0) {
          this.validationController.validate();
        } else {
          this.updateNet();
        }
      });
  }

  private updateNet() {
    this.netService
      .put(this.net, this.net.Id)
      .then((res) => {
        this.eventAggregator.publish('netListReset', 1);
        this.toastService.showSuccess('net.updated');
        this.router.navigateToRoute('net-detail', { Id: this.net.Id });
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private deleteNet() {

    if (!(this.net.AquacomId == null && this.net.Locked === false)) {
      return this.toastService.showError('net.deletingDeclined');
    }

    this.deleteDialogService.confirmBeforeDelete(
      () => {
      this.netService
        .deleteNet(this.net.Id)
        .then((res) => {
          this.toastService.showSuccess('net.deleted');
          this.router.navigateToRoute('net-list');
        })
        .catch((err) => this.errorService.handleError(err));
      }
    );
  }

  private gotoParentView() {
    this.eventAggregator.publish('netListReset', 1);
    this.router.navigateToRoute('net-list');
  }
}
